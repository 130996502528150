import { BreadcrumbItemState } from '@platform-ui-kit/components-library/dist/types/components/wpp-breadcrumb/types'
import {
  WppTypography,
  WppIconCalendar,
  WppDivider,
  WppButton,
  WppIconDownload,
  WppIconLink,
  WppIconTrash,
  WppActionButton,
  WppIconOrganization,
  WppBreadcrumb,
  WppIconPin,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchProjectFileApi } from 'api/projects/queries/useFetchProjectFileApi'
import { Flex } from 'components/common/flex/Flex'
import { tableActions } from 'components/common/table'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { TableKey } from 'constants/table'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { useTemplate } from 'hooks/useTemplate'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { showFileDeleteModal } from 'pages/project/components/files/components/fileDeleteModal/FileDeleteModal'
import { FileModalSkeleton } from 'pages/project/components/files/components/fileDetailsModal/utils'
import styles from 'pages/project/components/files/Files.module.scss'
import { PIN_LIMIT } from 'pages/project/components/files/utils'
import { CopyType, useCopyUrlToClipboard } from 'pages/project/hooks/useCopyUrlToClipboard'
import { useDownloadProjectFile } from 'pages/project/hooks/useDownloadProjectFile'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { useTogglePinFile } from 'pages/project/hooks/useTogglePinFile'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { fullName, isEqualEmails } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'
import { formatDateByTime } from 'utils/dateFormat'
import { formatBytes } from 'utils/files'

export interface Props extends NiceModalWrappedProps {
  fileId: string
  isInFluid?: boolean
}

export const FileDetailsModal = ({ fileId, isInFluid, onClose, onCloseComplete, isOpen }: Props) => {
  const { t } = useTranslation()
  const { osContext } = useOs()
  const { userDetails } = osContext
  const projectContext = useProject()
  const templateContext = useTemplate()

  const isTemplate = !!templateContext

  const { copyToClipboardAction } = useCopyUrlToClipboard(CopyType.FILE)
  const { downloadFileAction } = useDownloadProjectFile()
  const { togglePinFile } = useTogglePinFile()

  const { isPermitted } = useIsPermitted()
  const { hasRole } = useHasProjectRole()
  const isOwner = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const {
    data: file,
    isLoading,
    isError,
    error,
  } = useFetchProjectFileApi({
    params: { id: fileId },
    enabled: !!fileId,
  })

  const canDelete = (isEqualEmails(userDetails.email, file?.createdBy.email) || isOwner) && !projectContext?.isInactive

  const breadcrumbsList: BreadcrumbItemState[][] = useMemo(() => {
    if (!file?.locations) return []

    return file.locations.map(location => {
      return location.map((path: string) => ({
        label: path,
        path: '',
      }))
    })
  }, [file?.locations])

  const extension = useMemo(() => {
    if (!file) return ''

    return file?.name.match(/\.[^.]+$/)![0] || file?.fileType!
  }, [file]).toUpperCase()

  const name = (file?.name || '').replace(/\.[^/.]+$/, '')

  const locationsCount = useMemo(() => {
    return breadcrumbsList.length
  }, [breadcrumbsList])

  const pinLimit = (file?.pinnedFilesCount ?? 0) >= PIN_LIMIT

  return (
    <SideModal
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      size="m"
      data-testid="files-details-modal"
    >
      <WppTypography slot="header" type="2xl-heading" data-testid="files-details-modal-title">
        {t('project.files.details_modal.title')}
      </WppTypography>
      <Flex slot="body" className={styles.modalBody} direction="column" gap={24}>
        {isLoading ? (
          <FileModalSkeleton />
        ) : !!file && !isError ? (
          <>
            <Flex direction="column" gap={12}>
              <WppTypography type="xl-heading" data-testid="file-details-modal-name">
                {name}
              </WppTypography>
              <Flex gap={16}>
                <Flex gap={16}>
                  <WppTypography
                    type="s-body"
                    className={styles.greyColor800}
                    data-testid="file-details-modal-extension"
                  >
                    {extension.substring(1)}
                  </WppTypography>

                  <div className={styles.separatorLine}>/</div>
                </Flex>
                <Flex gap={16}>
                  <WppTypography type="s-body" className={styles.greyColor800} data-testid="file-details-modal-size">
                    {formatBytes(file?.size || 0)}
                  </WppTypography>
                </Flex>
              </Flex>
              <Flex gap={16}>
                <WppTooltip text={t('project.files.details_modal.copy_link_tooltip')}>
                  <WppActionButton
                    variant="secondary"
                    onClick={copyToClipboardAction}
                    data-testid="copy-to-clipboard-button"
                    className={styles.actionButton}
                  >
                    <WppIconLink className={styles.greyColor800} />
                  </WppActionButton>
                </WppTooltip>
                <WppTooltip text={t('project.files.details_modal.download_tooltip')}>
                  <WppActionButton
                    variant="secondary"
                    onClick={() => downloadFileAction(file.key)}
                    data-testid="file-download-button"
                    className={styles.actionButton}
                  >
                    <WppIconDownload className={styles.greyColor800} />
                  </WppActionButton>
                </WppTooltip>

                {isOwner && !file.pinnedAt && !isTemplate && (
                  <WppTooltip
                    header={pinLimit ? t('project.files.pin.pin_tooltip_title') : ''}
                    text={pinLimit ? t('project.files.pin.pin_tooltip_text') : t('project.files.pin_action')}
                  >
                    <WppActionButton
                      variant="secondary"
                      disabled={pinLimit}
                      onClick={() => togglePinFile(file)}
                      data-testid="file-pin-button"
                    >
                      <WppIconPin
                        className={clsx({ [styles.greyColor800]: !pinLimit, [styles.greyColor400]: pinLimit })}
                      />
                    </WppActionButton>
                  </WppTooltip>
                )}

                {isOwner && file.pinnedAt && (
                  <WppTooltip text={t('project.files.unpin_action')}>
                    <WppActionButton
                      variant="secondary"
                      onClick={() => togglePinFile(file)}
                      data-testid="file-unpin-button"
                    >
                      <WppIconPin className={styles.primaryColor400} />
                    </WppActionButton>
                  </WppTooltip>
                )}

                {canDelete && (
                  <WppTooltip text={t('project.files.details_modal.delete_tooltip')}>
                    <WppActionButton
                      variant="secondary"
                      data-testid="file-remove-button"
                      className={styles.actionButton}
                      onClick={() =>
                        showFileDeleteModal({
                          fileId: file!.id,
                          isInFluid,
                          onDelete: () => {
                            tableActions.reload([TableKey.PROJECT_FILE_LIST])
                            onClose()
                          },
                        })
                      }
                    >
                      <WppIconTrash className={styles.greyColor800} />
                    </WppActionButton>
                  </WppTooltip>
                )}
              </Flex>
            </Flex>
            <WppDivider data-testid="file-details-modal-separator" />

            <Flex direction="column" gap={24}>
              <Flex gap={24} align="center">
                <Flex className={styles.icon} align="center" justify="center">
                  <WppIconCalendar />
                </Flex>
                <Flex direction="column">
                  <WppTypography type="xs-midi" className={styles.greyColor800} data-testid="uploaded-by-label">
                    {t('project.files.uploaded_by')}
                  </WppTypography>
                  <WppTypography type="s-body">
                    {fullName(file?.createdBy?.firstname, file?.createdBy?.lastname)}{' '}
                    {t('project.files.uploaded_on', {
                      date: formatDateByTime(file.createdAt, userDetails.dateLocale),
                    })}
                  </WppTypography>
                </Flex>
              </Flex>

              {breadcrumbsList.length > 0 && (
                <Flex gap={24} align="center">
                  <Flex className={styles.icon} align="center" justify="center">
                    <WppIconOrganization />
                  </Flex>
                  <Flex direction="column">
                    <WppTypography
                      type="xs-midi"
                      className={styles.greyColor800}
                      data-testid="workflow-locations-label"
                    >
                      {locationsCount > 1 ? t('project.files.locations') : t('project.files.location')}
                    </WppTypography>

                    <Flex direction="column" gap={4}>
                      {breadcrumbsList.map((breadcrumbs, idx) => (
                        <WppBreadcrumb
                          key={idx}
                          items={breadcrumbs}
                          className={clsx(styles.breadcrumb, { [styles.single]: breadcrumbs.length === 1 })}
                          maxLabelLength={Number.POSITIVE_INFINITY}
                          data-testid="workflow-locations-breadcrumbs"
                        />
                      ))}
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </>
        ) : (
          <ErrorState error={error} />
        )}
      </Flex>
      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="primary" size="m" onClick={onClose}>
          {t('common.btn_done')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showFileDetailsModal, useModal: useShowFileDetailsModal } = createProjectModal<Props>(
  FileDetailsModal,
  'file-details-modal',
)
