import { facadeApi } from 'api'
import { HierarchyTree } from 'api/hubs/fetchers/utils'

interface Params {
  tenantId: string
}

/**
 * @deprecated
 */
export const getTenantHierarchy = ({ tenantId }: Params) =>
  facadeApi.get<HierarchyTree>(`/v2/tenants/${tenantId}/navigation-tree`)
